import React, { SVGProps } from "react";

class Logo extends React.PureComponent<SVGProps<SVGSVGElement>> {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="120"
        height="120"
        fill="none"
        viewBox="0 0 120 120"
        {...this.props}>
        <path
          fill="#fff"
          d="M47.649 29.96l-8.234 8.31L0 18.251h43.532c0 3.839.411 7.13 4.117 11.709zM58.353 101.748L41.61 40.874l8.509-8.637c2.333 1.645 4.94 2.879 8.234 3.231v66.28zM72.351 29.96l8.234 8.31L120 18.251H76.468c0 3.839-.411 7.13-4.117 11.709zM61.647 101.748L78.39 40.874l-8.509-8.637c-2.333 1.645-4.94 2.879-8.234 3.231v66.28zM58.628 21.542c-4.007.373-4.071 1.06-4.117 1.783-.093.56-.15.74-.218 1.322-.023.163-.047.303-.047.466-.023.164-.023.327-.046.467-.023.163-.023.326-.046.489-.023.163-.023.326-.046.49l-.146 4.638c2.054.742 3.788 1.04 5.723 1.04 1.934 0 4.145-.318 5.942-1.04-.092-.49-.442-1.12-.558-1.655-.069-.35-.138-.723-.23-1.096-.046-.186-.069-.373-.115-.559-.07-.373-.161-.77-.253-1.189.23-.14.437-.256.644-.373.023 0 .023-.023.047-.023.069-.046.138-.07.207-.116 1.404-.793 1.796-1.003 3.316 0 .806-4.429-4.214-4.78-10.057-4.644z"></path>
      </svg>
    );
  }
}

export default React.memo(Logo);
