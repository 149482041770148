import { ILoginFormData } from "../../pages/auth/login";
import client from "../client";

const endpoint = "/auth";

const refreshToken = async (refreshToken: string) =>
  client.post(`${endpoint}/refresh`, { token: refreshToken });

const login = async (data: ILoginFormData) =>
  client.post(`${endpoint}/login/admin`, data);

export default { login, refreshToken };
