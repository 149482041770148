import React from "react";
import { useNavigate } from "react-router-dom";
//types
import { IPaginatorProps } from "../pagination";
import { IUser } from "../../../api/models/user";
//components
import Paginator from "../pagination";
import dayjs from "dayjs";

interface IProps {
  data?: IUser[];
  pagination: IPaginatorProps;
}

const UserListTemplate = (props: IProps) => {
  const navigate = useNavigate();

  function renderRow(row: IUser) {
    let cells: React.ReactNode[] = [];
    cells.push(
      <td className="pl-2 min-w-fit truncate border-l border-r border-b" key={"name-" + row._id}>
        {row.profile.name}
      </td>
    );
    cells.push(
      <td className="pl-2 min-w-fit truncate border-l border-r border-b" key={"e-mail-" + row._id}>
        {row.email}
      </td>
    );
    // cells.push(
    //   <td
    //     className="pl-2 min-w-fit truncate border-l border-r border-b"
    //     key={"cpr-" + row._id}>
    //     {row.cpr}
    //   </td>
    // );
    cells.push(
      <td
        className="pl-2 min-w-fit truncate border-l border-r border-b"
        key={"birthdate-" + row._id}>
        {dayjs(row.birthdate).format("DD/MM/YYYY")}
      </td>
    );
    cells.push(
      <td className="pl-2 min-w-fit truncate border-l border-r border-b" key={"city-" + row._id}>
        {row.city}
      </td>
    );
    return cells;
  }

  return props.data && props.data.length > 0 ? (
    <div className="flex">
      <table className="w-full mt-8 border-collapse mx-8 text-left leading-8 table-fixed">
        <thead className="capitalize">
          <tr>
            <th className="min-w-fit pl-2 border-b" key="header1">
              Name
            </th>
            <th className="min-w-fit pl-2 border-b" key="header2">
              Email
            </th>
            {/* <th className="min-w-fit pl-2 border-b" key="header3">
              CPR
            </th> */}
            <th className="min-w-fit pl-2 border-b" key="header4">
              Birthdate
            </th>
            <th className="min-w-fit pl-2 border-b" key="header5">
              City
            </th>
          </tr>
        </thead>
        <tbody>
          {props.data.map(
            (x, i) =>
              i < 15 && (
                <tr
                  key={i}
                  // className="border-b hover:bg-gray-200 cursor-pointer"
                  className="hover:bg-gray-200 cursor-pointer"
                  onClick={() => navigate("/users/details", { state: { id: x._id } })}>
                  {renderRow(x)}
                </tr>
              )
          )}
        </tbody>
        <tfoot>
          <tr>
            <td className="pt-8" colSpan={5}>
              <Paginator
                currentPage={props.pagination.currentPage}
                pageSize={props.pagination.pageSize}
                totalItems={props.pagination.totalItems}
                onNavigate={(page) => props.pagination.onNavigate(page)}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  ) : null;
};

export default UserListTemplate;
