import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";

import IconButton from "../../../components/elements/buttons/IconButton";
import PageFooter from "../../../components/layouts/footer/page-footer";
import PageHeader from "../../../components/layouts/header/page-header";
import BusListTemplate from "../../../components/tables/busList";
import ModalError from "../../../components/modals/modal-error";
import ModalLoader2 from "./../../../components/modals/modal-loader-2/index";

import busesApi from "../../../api/main/buses";
import useApi from "../../../hooks/useApi";
import { IBus } from "../../../api/models/bus";
import { useNavigate } from "react-router-dom";

function BusesList(props: any) {
  const [buses, setBuses] = useState<IBus[]>();
  const [totalBuses, setTotalBuses] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const navigate = useNavigate();

  const pageSize = 15;

  const getAllBusesApi = useApi(busesApi.getAll);
  const getBusCountApi = useApi(busesApi.getBusCount);

  useEffect(() => {
    async function getCount() {
      const response = await getBusCountApi.request();
      if (!response.ok) {
        setErrorMessage(response.data.errorMessage || response.data);
        setErrorModalVisible(true);
      } else setTotalBuses(response.data);
    }
    getCount();
  }, []);

  useEffect(() => {
    async function loadData() {
      const response = await getAllBusesApi.request(currentPage, pageSize);
      if (!response.ok) {
        setErrorMessage(response.data.errorMessage || response.data);
        setErrorModalVisible(true);
      } else setBuses(response.data);
    }
    loadData();
  }, [currentPage]);

  return (
    <div className="w-full bg-background flex flex-col">
      <ModalLoader2
        visible={getAllBusesApi.loading || getBusCountApi.loading}
      />
      <ModalError
        visible={errorModalVisible}
        errorMessage={errorMessage}
        onClose={() => setErrorModalVisible(false)}
      />
      <PageHeader title="Buses" />
      <div className="absolute right-8 top-2">
        <IconButton
          label="create bus"
          icon={BiPlus}
          iconSide="left"
          onClick={() => navigate("/buses/create")}
        />
      </div>
      {buses && buses?.length > 0 ? (
        <BusListTemplate
          headers={["username", "name", "description"]}
          data={buses}
          pagination={{
            currentPage: currentPage,
            pageSize: pageSize,
            totalItems: totalBuses,
            onNavigate: (page) => setCurrentPage(page),
          }}
        />
      ) : (
        <p className="text-center">There are no buses registered.</p>
      )}
      <div className="flex flex-1" />
      <PageFooter />
    </div>
  );
}

export default BusesList;
