import { Combobox, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { BiCheck } from "react-icons/bi";
import { TbSelector } from "react-icons/tb";
import { IDropdownSelection } from "./../../../api/models/dropdown";

interface IProps {
  data: IDropdownSelection[];
  value: IDropdownSelection[];
  onSelect: (value: IDropdownSelection[]) => void;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
}

function MultiselectDropdown(props: IProps) {
  const [query, setQuery] = useState("");

  const filteredData =
    query === ""
      ? props.data
      : props.data.filter((record) =>
          record.label
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  return (
    <Combobox
      value={props.value}
      onChange={props.onSelect}
      multiple
      disabled={props.disabled}>
      <div className="relative pb-1">
        {props.label && (
          <Combobox.Label className="text-sm">{props.label}</Combobox.Label>
        )}
        <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
          <Combobox.Input
            className="w-full border-none rounded-lg py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 z-0"
            value={query}
            onChange={(event) => setQuery(event.target.value)}
            placeholder={props.placeholder}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <TbSelector className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}>
          <Combobox.Options
            className={`z-10 absolute mt-1 max-h-60 w-max-sm min-w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}>
            {filteredData.length === 0 && query !== "" ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                Nothing found.
              </div>
            ) : (
              filteredData.map((data) => (
                <Combobox.Option
                  key={data.value}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? "bg-red-600 text-white" : "text-gray-900"
                    }`
                  }
                  value={data}>
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`block  ${
                          selected ? "font-medium" : "font-normal"
                        }`}>
                        {data.label}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            active ? "text-white" : "text-red-600"
                          }`}>
                          <BiCheck className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
}

export default MultiselectDropdown;
