import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import PageHeader from "../../../components/layouts/header/page-header";
import PasswordField from "../../../components/elements/forms/PasswordField";
import Error from "../../../components/elements/forms/Error";
import ModalError from "../../../components/modals/modal-error";
import ToasterSuccess from "../../../components/elements/toasters/success";
import Button from "../../../components/elements/buttons/Button";
import PageFooter from "../../../components/layouts/footer/page-footer";

import useApi from "../../../hooks/useApi";
import adminsApi from "../../../api/main/admins";
import { platformUserPattern } from "../../../constants/passwords";

export interface IAdminChangePasswordForm {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
}

const AdminProfile = () => {
  const [toasterVisible, setToasterVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const navigate = useNavigate();

  const changeAdminPassApi = useApi(adminsApi.changePassword);

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<IAdminChangePasswordForm>();

  const profileRules = {
    password: {
      required: "Required field",
      validate: {
        value: (password: string) => {
          if (password.length < 8)
            return "Password must be at least 8 characters long.";
          if (password.length > 64)
            return "Password cannot exceed 64 characters.";
          return true;
        },
      },
      pattern: {
        value: platformUserPattern,
        message:
          "Password should contain lowercase, uppercase letters and numbers.",
      },
    },
    confirmNewPassword: {
      required: "Required field",
      validate: {
        value: (confirmNewPassword: string) => {
          const newPassword = getValues("newPassword");

          if (confirmNewPassword.length < 8)
            return "Password must be at least 8 characters long.";
          if (confirmNewPassword !== newPassword)
            return "The passwords do not match.";
          if (confirmNewPassword.length > 64)
            return "Password cannot exceed 64 characters.";
          return true;
        },
      },
      pattern: {
        value: platformUserPattern,
        message:
          "Password should contain lowercase, uppercase letters and numbers.",
      },
    },
  };

  const onSubmit: SubmitHandler<IAdminChangePasswordForm> = async (
    data: IAdminChangePasswordForm
  ) => {
    setToasterVisible(false);

    const response = await changeAdminPassApi.request(data);
    if (!response.ok) {
      setErrorMessage(response.data.errorMessage || response.data);
      setErrorModalVisible(true);
    } else {
      setToasterVisible(true);
      setTimeout(() => {
        navigate(-1);
      }, 3000);
    }
  };

  return (
    <div className="w-full">
      {/* <ModalLoader2 visible={createBusApi.loading} /> */}
      <ModalError
        visible={errorModalVisible}
        errorMessage={errorMessage || ""}
        onClose={() => setErrorModalVisible(false)}
      />
      <ToasterSuccess
        message="Password was changed successfully."
        visible={toasterVisible}
        onClose={() => setToasterVisible(false)}
      />
      <section aria-label="admin profile header">
        <PageHeader title="Profile" />
      </section>
      <section
        aria-label="admin profile actions"
        className="flex flex-1 flex-row items-center h-16 border-y px-4 bg-red-50">
        <div className="absolute right-4">
          <Button label="Update" onClick={() => handleSubmit(onSubmit)()} />
        </div>
      </section>
      <section
        aria-label="main section"
        className="flex w-full px-8 justify-center">
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className="flex w-full flex-col gap-4 justify-center items-center mt-10">
          <div className="w-1/3">
            <Controller
              control={control}
              name="password"
              //   rules={profileRules.password}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <PasswordField
                  ref={ref}
                  placeholder="Enter password"
                  label="Current Password"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                />
              )}
            />
            {/* <Error
              visible={Boolean(errors?.password)}
              text={errors?.password?.message}
            /> */}
          </div>
          <div className="w-1/3">
            <Controller
              control={control}
              name="newPassword"
              rules={profileRules.password}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <PasswordField
                  ref={ref}
                  placeholder="Enter new password"
                  label="New Password"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                />
              )}
            />
            <Error
              visible={Boolean(errors?.newPassword)}
              text={errors?.newPassword?.message}
            />
          </div>
          <div className="w-1/3">
            <Controller
              control={control}
              name="confirmNewPassword"
              rules={profileRules.confirmNewPassword}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <PasswordField
                  ref={ref}
                  placeholder="Confirm new password"
                  label="Confirm Password"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                />
              )}
            />
            <Error
              visible={Boolean(errors?.confirmNewPassword)}
              text={errors?.confirmNewPassword?.message}
            />
          </div>
        </form>
      </section>
      <section
        aria-label="admin profile footer"
        className="absolute right-4 bottom-1">
        <PageFooter />
      </section>
    </div>
  );
};

export default AdminProfile;
