import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//icons
import { CgProfile } from "react-icons/cg";
import { FaShuttleVan } from "react-icons/fa";
import { MdOutlinePowerSettingsNew } from "react-icons/md";
import { TbUsers, TbNurse } from "react-icons/tb";

import { userLoggedOut, selectUserId } from "../../../features/authSlice";
import LogoShort from "../../../assets/tsx/LogoShort";
import ModalDialog from "../../modals/modal-dialog";
import useApi from "./../../../hooks/useApi";
import platformUsersApi from "../../../api/main/platformUser";
import ModalError from "../../modals/modal-error";
import ModalLoader2 from "../../modals/modal-loader-2";
import { selectUserRole } from "./../../../features/authSlice";

const Sidebar = () => {
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [nameObject, setNameObject] = useState({ firstname: "", lastname: "" });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getProfileNameApi = useApi(platformUsersApi.getMyName);
  //TODO: load profile
  const userId = useSelector(selectUserId);
  const role = useSelector(selectUserRole);

  useEffect(() => {
    async function getProfileName() {
      const response = await getProfileNameApi.request();
      if (!response.ok) {
        setErrorMessage(response.data.errorMessage || response.data);
        setErrorModalVisible(true);
      } else setNameObject(response.data);
    }

    getProfileName();
  }, []);

  return (
    <aside className="flex h-screen flex-col justify-between  sticky top-0 sm:w-48 w-16 bg-secondary drop-shadow-[4px_0px_8px_rgba(0,0,0,0.3)]">
      {/* <ModalLoader2 visible={getProfileNameApi.loading} /> */}
      <ModalDialog
        visible={logoutModalVisible}
        onClose={() => setLogoutModalVisible(false)}
        title="Logout"
        text="Are you sure you want to logout from your account?"
        acceptButtonLabel="Logout"
        cancelButtonLabel="Cancel"
        handleAccept={() => {
          navigate("/", { replace: true });
          dispatch(userLoggedOut());
        }}
      />
      <ModalError
        visible={errorModalVisible}
        errorMessage={errorMessage}
        onClose={() => setErrorModalVisible(false)}
      />
      <div>
        <div className="flex p-4">
          <LogoShort fill={"#e2231a"} className="text-2xl" />
          <p className="font-serif font-normal tracking-wider hidden sm:flex text-white text-xs  ml-3 mt-1.5">
            DANSKE LÆGERS VACCINATIONS SERVICE
          </p>
        </div>
        {role === "admin" && (
          <ul className="mt-12">
            {/* <li className="flex w-full justify-center sm:justify-between text-background hover:text-yellow-500 cursor-pointer items-center ">
            <NavLink
              to="admins"
              className={({ isActive }) =>
                `flex flex-1 flex-row justify-center sm:justify-start items-center py-3 sm:px-8 ${
                  isActive ? "text-yellow-600" : ""
                }`
              }>
              <MdAdminPanelSettings className="text-4xl sm:text-xl" />
              <span className="text-sm ml-2 hidden sm:flex pt-0.5">Admins</span>
            </NavLink>
          </li> */}
            <li className="flex w-full justify-center sm:justify-start text-background  hover:text-yellow-500  cursor-pointer items-center">
              <NavLink
                to="buses"
                className={({ isActive }) =>
                  `flex flex-1 flex-row justify-center sm:justify-start items-center py-3 sm:px-8 ${
                    isActive ? "text-yellow-600" : ""
                  }`
                }>
                <FaShuttleVan className="text-4xl sm:text-xl" />
                <span className="text-sm ml-2 hidden sm:flex pt-0.5">
                  Buses
                </span>
              </NavLink>
            </li>
            {/* <li className="flex w-full justify-center sm:justify-between text-background hover:text-yellow-500 cursor-pointer items-center ">
              <NavLink
                to="nurses"
                className={({ isActive }) =>
                  `flex flex-1 flex-row justify-center sm:justify-start items-center py-3 sm:px-8 ${
                    isActive ? "text-yellow-600" : ""
                  }`
                }>
                <TbNurse className="text-4xl sm:text-xl " />
                <span className=" text-sm ml-2 hidden sm:flex pt-0.5">
                  Nurses
                </span>
              </NavLink>
            </li> */}
            <li className="flex w-full justify-center sm:justify-start text-background hover:text-yellow-500  cursor-pointer items-center">
              <NavLink
                to="users"
                className={({ isActive }) =>
                  `flex flex-1 flex-row justify-center sm:justify-start items-center py-3 sm:px-8 ${
                    isActive ? "text-yellow-600" : ""
                  }`
                }>
                <TbUsers className="text-4xl sm:text-xl" />
                <span className="text-sm ml-2 hidden sm:flex pt-0.5">
                  Users
                </span>
              </NavLink>
            </li>
          </ul>
        )}
        {role === "nurse" && (
          <ul className="mt-12">
            <li className="flex w-full justify-center sm:justify-start text-background hover:text-yellow-500  cursor-pointer items-center">
              <NavLink
                to="users"
                className={({ isActive }) =>
                  `flex flex-1 flex-row justify-center sm:justify-start items-center py-3 sm:px-8 ${
                    isActive ? "text-yellow-600" : ""
                  }`
                }>
                <TbUsers className="text-4xl sm:text-xl" />
                <span className="text-sm ml-2 hidden sm:flex pt-0.5">
                  Users
                </span>
              </NavLink>
            </li>
          </ul>
        )}
      </div>
      <div>
        <div className="flex justify-center sm:justify-start sm:px-8 mb-4">
          <div className="flex justify-center">
            <CgProfile
              onClick={() => navigate("profile")}
              className="text-4xl sm:text-xl sm:mr-2 text-white sm:cursor-default cursor-pointer"
            />
          </div>
          <div className="flex justify-start flex-col">
            <p className="text-background text-sm font-medium hidden sm:flex">
              {`${nameObject.firstname} ${nameObject.lastname}`}
            </p>
            <Link
              to="profile"
              className="text-background text-xs hover:text-gray-300 cursor-pointer hidden sm:flex">
              Edit Profile
            </Link>
          </div>
        </div>
        <div className="px-8 border-t border-gray-700 flex flex-1 justify-center sm:justify-start text-background">
          <div
            className="my-4 flex flex-row justify-center items-center sm:justify-start hover:text-gray-300 cursor-pointer"
            onClick={() => setLogoutModalVisible(true)}>
            <MdOutlinePowerSettingsNew className="sm:text-background text-red-600 text-4xl sm:text-xl" />
            <span className="text-inherit text-sm  ml-2 hidden sm:flex pt-0.5">
              Logout
            </span>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
