export default [
  {
    value: "questionaire.question4.answer1",
    label: "Rejser af kortere eller længere varighed i Danmark og Norden",
  },
  {
    value: "questionaire.question4.answer2",
    label: "Storbyrejser/forretningsrejser i kortere tid over hele verden",
  },
  {
    value: "questionaire.question4.answer3",
    label:
      "Rejser under ordnede forhold til destinationer i Europa, fx Sydeuropa",
  },
  {
    value: "questionaire.question4.answer4",
    label:
      "Rejser under ordnede forhold til destinationer uden for Europa, fx Egypten eller Thailand",
  },
  {
    value: "questionaire.question4.answer5",
    label:
      "Rejser på egen hånd under mere primitive forhold, fx ophold i fattige områder",
  },
  {
    value: "questionaire.question4.answer6",
    label:
      "Ophold i længere tid under meget primitive forhold, fx jungle eller meget fattige områder",
  },
];
