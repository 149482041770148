import React from "react";
import { BiChevronDown } from "react-icons/bi";

interface IProps {
  onClick: () => void;
  isActive: boolean;
}

function FiltersAnimatedButton({ onClick, isActive }: IProps) {
  return (
    <div
      className={`${
        isActive ? "w-10" : "w-24"
      } h-10 bg-red-600 hover:bg-red-700 active:bg-red-800 flex justify-center items-center rounded-full transition-all duration-500 cursor-pointer`}
      onClick={onClick}>
      <span
        className={`text-white ${
          isActive ? "text-opacity-0 ml-0" : "text-opacity-100 ml-2"
        } transition-all duration-500 ease-in`}>
        Filters
      </span>
      <span
        className={`text-white ${isActive ? "-ml-10" : "ml-2"} mt-0.5 ${
          isActive && "rotate-180"
        } transition-all duration-500 ease-in`}>
        <BiChevronDown size="1.5rem" />
      </span>
    </div>
  );
}

export default FiltersAnimatedButton;
