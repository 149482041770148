export default [
  {
    value: "J07BB02",
    label: "Influenza",
  },

  {
    value: "J07AL01",
    label: "Lungebetændelse",
  },

  {
    value: "J07BA01",
    label: "TBE/TBE barn",
  },

  {
    value: "J07AJ52",
    label: "Difteri - Stivkrampe - Kighoste",
  },

  {
    value: "J07BK01",
    label: "Skoldkopper",
  },

  {
    value: "J07AH08",
    label: "Meningitis",
  },

  {
    value: "J07BK03",
    label: "Helvedsild",
  },

  {
    value: "J07BD52",
    label: "MFR",
  },

  {
    value: "J07BL01",
    label: "Gul feber",
  },

  {
    value: "J07BC",
    label: "Hepatitis A",
  },

  {
    value: "J07BC20",
    label: "Hepatitis A+B / Hep A+B barn",
  },

  {
    value: "J07BC01",
    label: "Hepatitis B / Hep B barn",
  },

  {
    value: "J07BM03",
    label: "HPV - Gardasil",
  },

  {
    value: "J07BA02",
    label: "Japansk Hjertebetændelse",
  },

  {
    value: "J07AE01",
    label: "Kolera",
  },

  {
    value: "J07BG01",
    label: "Rabies",
  },

  {
    value: "J07AP03",
    label: "Tyfus",
  },

  {
    value: "J07BF03",
    label: "Polio",
  },
];
