import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import PageHeader from "../../../components/layouts/header/page-header";
import PageFooter from "../../../components/layouts/footer/page-footer";
import ModalError from "../../../components/modals/modal-error";
import ModalLoader2 from "./../../../components/modals/modal-loader-2/index";
import UserListTemplate from "../../../components/tables/userList";
import FiltersAnimatedButton from "../../../components/elements/buttons/FiltersAnimatedButton";
import Search from "../../../components/elements/forms/Search";
import FromToField from "../../../components/elements/forms/FromToField";
import Button from "../../../components/elements/buttons/Button";
import Tooltip from "../../../components/elements/tooltip";
import MultiselectDropdown from "../../../components/elements/dropdowns/MultiselectDropdown";

import usersApi from "../../../api/main/users";
import useApi from "../../../hooks/useApi";
import { IPaginatedFilteredUsers, IUser, IUserFilters } from "./../../../api/models/user";
import activities from "../../../constants/user-filters/activities";
import destinations from "../../../constants/user-filters/destinations";
import regions from "../../../constants/user-filters/regions";
import vaccines from "../../../constants/user-filters/vaccines";
import { IDropdownSelection } from "./../../../api/models/dropdown";
import { selectUserRole } from "../../../features/authSlice";

function UsersList2(props: any) {
  const [users, setUsers] = useState<IUser[]>();
  const [totalUsers, setTotalUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  //filter values
  const [ageValueFrom, setAgeValueFrom] = useState("");
  const [ageValueTo, setAgeValueTo] = useState("");
  const [activitiesSelections, setActivitiesSelections] = useState<IDropdownSelection[]>([]);
  const [destinationSelections, setDestinationSelections] = useState<IDropdownSelection[]>([]);
  const [regionSelections, setRegionSelections] = useState<IDropdownSelection[]>([]);
  const [vaccinesYesSelections, setVaccinesYesSelections] = useState<IDropdownSelection[]>([]);
  const [vaccinesNoSelections, setVaccinesNoSelections] = useState<IDropdownSelection[]>([]);
  const [filters, setFilters] = useState<IUserFilters>();

  const navigate = useNavigate();
  const role = useSelector(selectUserRole);

  const pageSize = 15;

  const getAllUsersApi = useApi(usersApi.getAll);
  const getUserCountApi = useApi(usersApi.getUserCount);
  const getFilterdUsersApi = useApi(usersApi.getFilteredUsers);
  const getAllFilteredUserIdsApi = useApi(usersApi.getAllFilteredUserIds);

  useEffect(() => {
    const getCount = async () => {
      const response = await getUserCountApi.request();
      if (!response.ok) {
        setErrorMessage(response.data.errorMessage || response.data);
        setErrorModalVisible(true);
      } else {
        setErrorMessage("");
        setErrorModalVisible(false);
        setTotalUsers(response.data);
      }
    };
    getCount();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      const response = await getAllUsersApi.request(currentPage, pageSize);
      if (!response.ok) {
        setErrorMessage(response.data.errorMessage || response.data);
        setErrorModalVisible(true);
      } else {
        setErrorMessage("");
        setErrorModalVisible(false);
        setUsers(response.data);
      }
    };

    loadData();
  }, [currentPage]);

  useEffect(() => {
    handleSearch();
  }, [filters]);

  const handleSearch = async () => {
    const count = await getAllFilteredUserIdsApi.request(filters);
    if (!count.ok) {
      setErrorMessage(count.data.errorMessage || count.data);
      setErrorModalVisible(true);
      return;
    }
    setTotalUsers(count.data.length);
    setCurrentPage(1);

    const response = await getFilterdUsersApi.request({
      filters: filters,
      size: pageSize,
      page: currentPage
    } as IPaginatedFilteredUsers);
    if (!response.ok) {
      setErrorMessage(response.data.errorMessage || response.data);
      setErrorModalVisible(true);
      return;
    }
    return setUsers(response.data);
  };

  const openNotificationPage = async () => {
    //get IDs of all filtered users
    const response = await getAllFilteredUserIdsApi.request(filters);
    if (!response.ok) {
      setErrorMessage(response.data.errorMessage || response.data);
      setErrorModalVisible(true);
      return;
    }
    //navigate to /notifications
    return navigate("/users/notifications", {
      state: { recipients: response.data }
    });
  };

  return (
    <div className="w-full max-h-screen overflow-y-auto bg-background flex flex-col">
      {/* <ModalLoader2
        visible={
          getAllUsersApi.loading ||
          getUserCountApi.loading ||
          getFilterdUsersApi.loading ||
          getAllFilteredUserIdsApi.loading
        }
      /> */}
      <ModalError
        visible={errorModalVisible}
        errorMessage={errorMessage}
        onClose={() => setErrorModalVisible(false)}
      />
      <PageHeader title="Users" />
      <section
        className={`flex flex-col gap-4 pr-7 lg:flex-row justify-between  ${
          isFilterExpanded ? "border-t" : "border-y"
        } items-center bg-red-50 max-h-fit pl-4 py-4`}
        aria-label="user list search">
        <div className="flex gap-4">
          <div className="sm:w-96">
            <Search
              value={searchValue}
              // placeholder="Search name or CPR"
              placeholder="Search name"
              onSubmit={(value) =>
                setFilters((prev) => ({ ...prev, search: value } as IUserFilters))
              }
              onChange={(value) => {
                setSearchValue(value);
              }}
            />
          </div>
          <FiltersAnimatedButton
            onClick={() => setIsFilterExpanded(!isFilterExpanded)}
            isActive={isFilterExpanded}
          />
        </div>
        {role === "admin" && (
          <Tooltip message="Send Notifications to filtered users">
            <Button label="Send Notification" onClick={() => openNotificationPage()} />
          </Tooltip>
        )}
      </section>
      <section
        className={`w-full min-h-fit bg-red-50 transition-all shadow-md duration-500 ease-in-out overflow-clip px-4 ${
          isFilterExpanded ? "2xl:h-24 h-auto overflow-y-visible" : "h-0"
        }`}>
        <div
          className={`flex flex-col justify-start space-y-2 z-0 2xl:flex-row 2xl:justify-between 2xl:items-end 2xl:space-x-2 pb-8 ${
            isFilterExpanded ? "opacity-100" : "opacity-0"
          } transition-all duration-1000`}>
          <FromToField
            label="Age"
            onChangeFrom={(value) => setAgeValueFrom(value.replace(/\D/g, ""))}
            onChangeTo={(value) => setAgeValueTo(value.replace(/\D/g, ""))}
            valueFrom={ageValueFrom}
            valueTo={ageValueTo}
          />
          <MultiselectDropdown
            data={activities}
            placeholder="Select activities..."
            label="Activities"
            value={activitiesSelections}
            onSelect={(selections) => setActivitiesSelections(selections)}
          />
          <MultiselectDropdown
            data={destinations}
            placeholder="Select destination types..."
            label="Destination types"
            value={destinationSelections}
            onSelect={(selections) => setDestinationSelections(selections)}
          />
          <MultiselectDropdown
            data={regions}
            placeholder="Select regions..."
            label="Regions"
            value={regionSelections}
            onSelect={(selections) => setRegionSelections(selections)}
          />
          <MultiselectDropdown
            data={vaccines}
            placeholder="Select received vaccines..."
            label="Received vaccines"
            value={vaccinesYesSelections}
            onSelect={(selections) => setVaccinesYesSelections(selections)}
          />
          <MultiselectDropdown
            data={vaccines}
            placeholder="Select not received vaccines..."
            label="Not received vaccines"
            value={vaccinesNoSelections}
            onSelect={(selections) => setVaccinesNoSelections(selections)}
          />
          <div className="flex gap-8 2xl:gap-2">
            <div className="pt-6 z-50">
              <Button
                label="Apply"
                onClick={() => {
                  setFilters({
                    search: searchValue,
                    age: { from: ageValueFrom, to: ageValueTo },
                    activities: activitiesSelections,
                    destinations: destinationSelections,
                    regions: regionSelections,
                    vaccines: vaccinesYesSelections,
                    vaccinesNot: vaccinesNoSelections
                  });
                }}
              />
            </div>
            <div className="pt-6">
              <Button
                label="Clear"
                onClick={() => {
                  setFilters({
                    search: "",
                    age: { from: "", to: "" },
                    activities: undefined,
                    destinations: undefined,
                    regions: undefined,
                    vaccines: undefined,
                    vaccinesNot: undefined
                  });
                  setSearchValue("");
                  setAgeValueFrom("");
                  setAgeValueTo("");
                  setActivitiesSelections([]);
                  setDestinationSelections([]);
                  setRegionSelections([]);
                  setVaccinesNoSelections([]);
                  setVaccinesYesSelections([]);
                }}
              />
            </div>
          </div>
        </div>
      </section>
      {users && users.length > 0 ? (
        <UserListTemplate
          data={users}
          pagination={{
            currentPage: currentPage,
            pageSize: pageSize,
            totalItems: totalUsers,
            onNavigate: (page) => setCurrentPage(page)
          }}
        />
      ) : (
        <p className="p-8 text-lg">No users found that match the criteria</p>
      )}
      <PageFooter />
    </div>
  );
}

export default UsersList2;
