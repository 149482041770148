import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { TbSend } from "react-icons/tb";

import PageHeader from "../../../components/layouts/header/page-header";
import PageFooter from "../../../components/layouts/footer/page-footer";
import ModalLoader2 from "../../../components/modals/modal-loader-2/index";
import ModalError from "../../../components/modals/modal-error";
import ToasterSuccess from "../../../components/elements/toasters/success";
import IconButton from "../../../components/elements/buttons/IconButton";

import useApi from "../../../hooks/useApi";
import adminsApi from "../../../api/main/admins";
import {
  INotification,
  INotificationDto,
} from "../../../api/models/notification";

interface IState {
  recipients: { _id: string }[];
}

const Notifications = () => {
  const [toasterVisible, setToasterVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [notification, setNotification] = useState<INotification>(
    {} as INotification
  );
  const [failedBatchesIndexes, setFailedBatchesIndexes] = useState<number[]>([]);
  const state = useLocation().state as IState;

  const sendNotificationApi = useApi(adminsApi.sendPushNotifications);

  const handleSend = async () => {
    setFailedBatchesIndexes([]);
    setErrorModalVisible(false);
    setErrorMessages([]);

    const recipientIds =  state.recipients.map((x) => x._id);
    const recipientIdBatches = breakIntoBatches(recipientIds, 1000);

    recipientIdBatches.forEach(async (batch,index)=>{
      const data: INotificationDto = {
        recipientIds: batch,
        ...notification,
      };

      const response = await sendNotificationApi.request(data);

      if (!response.ok) {
        setFailedBatchesIndexes(prev=>[...prev,index]);
        setErrorMessages(prev=>[...prev,`Batch Index: ${index}. Error: ${response?.data?.errorMessage || response?.data || "unknown"}`]);
      }
  });
    if(failedBatchesIndexes.length>0){
      // console.log(errorMessages);
      setErrorModalVisible(true);
      return;
    }
    
    setToasterVisible(true);
  };

const handleRetry = () => {
  setErrorModalVisible(false);
  setErrorMessages([]);
  const recipientIds =  state.recipients.map((x) => x._id);
  const recipientIdBatches = breakIntoBatches(recipientIds, 1000);
  const batchesToRetry = recipientIdBatches.filter((x, index)=>failedBatchesIndexes.some(y=>y===index));
  setFailedBatchesIndexes([]);
  batchesToRetry.forEach(async (batch,index)=>{
    const data: INotificationDto = {
      recipientIds: batch,
      ...notification,
    };
    const response = await sendNotificationApi.request(data);

    if (!response.ok) {
      setFailedBatchesIndexes(prev=>[...prev,index]);
      setErrorMessages(prev=>[...prev,`Batch Index: ${index}. Error: ${response?.data?.errorMessage || response?.data || "unknown"}`]);
    }
  });
  if(failedBatchesIndexes.length>0){
    setErrorModalVisible(true);
    return;
  }
  
  setToasterVisible(true);
  
}
  
  function breakIntoBatches(initialArray:string[], batchPopulation:number) {
    let batches = [];
    let batch = [];
    for (let i = 0; i < initialArray.length; i++) {
      batch.push(initialArray[i]);
      if ((i + 1) % batchPopulation === 0 || i === initialArray.length - 1) {
        batches.push(batch);
        batch = [];
      }
    }
    return batches;
  }

  return (
    <div className="w-full max-h-screen overflow-y-auto">
      <ModalLoader2 visible={sendNotificationApi.loading} />
      <ModalError
        // visible={true}
        visible={errorModalVisible}
        errorMessage={errorMessages.join("\n") || ""}
        onClose={() => setErrorModalVisible(false)}
        // retryButton={true}
        retryButton={failedBatchesIndexes.length>0}
        onRetry={handleRetry}
      />
      <ToasterSuccess
        message="Notifications were received from the server."
        visible={toasterVisible}
        onClose={() => setToasterVisible(false)}
      />
      <section aria-label="notifications and news">
        <PageHeader title="Send notifications/Create News" />
      </section>
      <section
        aria-label="notifications actions"
        className="flex flex-1 justify-end items-center h-16 border-y px-4 bg-red-50">
        <IconButton
          label="Send"
          onClick={handleSend}
          iconSide="left"
          icon={TbSend}
        />
      </section>
      <section
        aria-label="main content"
        className="flex flex-col md:flex-row w-full py-4 px-8 justify-center">
        <article className="flex flex-1 flex-col gap-8 justify-start items-center">
          <div className="w-64">
            <img
              src={require("../../../assets/img/mobile-notification-template.png")}
            />
          </div>
          <div className="w-64">
            <img src={require("../../../assets/img/News-template.png")} />
          </div>
        </article>
        <article className="flex flex-1 flex-col px-8 py-8 md:px-12 lg:px-16 xl:px-24 md:py-0 bg-white">
          <label>Title</label>
          <input
            className="input h-8"
            placeholder="Enter title"
            maxLength={30}
            onChange={(e) => {
              const prev = notification;
              prev.title = e.target.value;
              setNotification({ ...prev });
            }}
          />
          <p className="text-right text-xs">{`Characters ${
            notification.title ? notification.title.length : "0"
          }/30`}</p>
          <label>Subtitle (iOS only)</label>
          <input
            className="input h-8"
            placeholder="Enter subtitle"
            maxLength={30}
            onChange={(e) => {
              const prev = notification;
              prev.subtitle = e.target.value;
              setNotification({ ...prev });
            }}
          />
          <p className="text-right text-xs">{`Characters ${
            notification.subtitle ? notification.subtitle.length : "0"
          }/30`}</p>
          <label>Image</label>
          <input
            className="input h-8"
            type="url"
            placeholder="Enter image URL"
            onChange={(e) => {
              const prev = notification;
              prev.imageUrl = e.target.value;
              setNotification({ ...prev });
            }}
          />
          <label className="mt-4">
            Message short (used for push notifications)
          </label>
          <textarea
            className="input pt-2 pb-3"
            placeholder="Enter message"
            maxLength={150}
            rows={3}
            onChange={(e) => {
              const prev = notification;
              prev.bodyShort = e.target.value;
              setNotification({ ...prev });
            }}
          />
          <p className="text-right text-xs">{`Characters ${
            notification.bodyShort ? notification.bodyShort.length : "0"
          }/150`}</p>
          <label>Message long (used for News viewed in app)</label>
          <textarea
            className="input pt-2 pb-3"
            rows={7}
            placeholder="Enter message"
            onChange={(e) => {
              const prev = notification;
              prev.bodyFull = e.target.value;
              setNotification({ ...prev });
            }}
          />
          <label className="mt-4">Valid from date</label>
          <input
            className="input h-8"
            type="date"
            placeholder="Enter date"
            onChange={(e) => {
              const prev = notification;
              prev.validFromDate = e.target.value;
              setNotification({ ...prev });
            }}
          />
          <label className="mt-4">Valid until date</label>
          <input
            className="input h-8"
            type="date"
            placeholder="Enter date"
            onChange={(e) => {
              const prev = notification;
              prev.validToDate = e.target.value;
              setNotification({ ...prev });
            }}
          />
          <label className="mt-4">Voucher code</label>
          <input
            className="input h-8"
            placeholder="Enter voucher code"
            maxLength={40}
            onChange={(e) => {
              const prev = notification;
              prev.voucherCode = e.target.value;
              setNotification({ ...prev });
            }}
          />
          <label className="mt-4">Link</label>
          <input
            className="input h-8"
            type="url"
            placeholder="Enter link URL"
            onChange={(e) => {
              const prev = notification;
              prev.linkUrl = e.target.value;
              setNotification({ ...prev });
            }}
          />
        </article>
      </section>
      <PageFooter />
    </div>
  );
};

export default Notifications;
