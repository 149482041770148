import client from "../client";
import { IBus, IBusCreate, IBusPassword } from "../models/bus";

const endpoint = "/buses";

const getAll = async (page: number, size: number) =>
  client.get(`${endpoint}?page=${page}&size=${size}`);
const getById = async (id: string) => client.get(`${endpoint}/${id}`);
const getBusCount = async () => client.get(`${endpoint}/total`);
const createBus = async (data: IBusCreate) => client.post(`${endpoint}`, data);
const updateBus = async (data: IBus) =>
  client.put(`${endpoint}/${data._id}`, data);
const deleteBus = async (id: string) => client.delete(`${endpoint}/${id}`);
const changePassword = async (data: IBusPassword) =>
  client.post(`${endpoint}/${data._id}/change-password`, data);

export default {
  getAll,
  getById,
  getBusCount,
  createBus,
  updateBus,
  deleteBus,
  changePassword,
};
