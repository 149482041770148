import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import PageHeader from "../../../components/layouts/header/page-header";
import ModalLoader2 from "./../../../components/modals/modal-loader-2/index";
import Field from "../../../components/elements/forms/Field";
import PasswordField from "../../../components/elements/forms/PasswordField";
import TextArea from "../../../components/elements/forms/TextArea";
import Error from "../../../components/elements/forms/Error";
import ModalError from "../../../components/modals/modal-error";
import ToasterSuccess from "../../../components/elements/toasters/success";
import Button from "../../../components/elements/buttons/Button";

import useApi from "../../../hooks/useApi";
import busesApi from "../../../api/main/buses";
import { IBusCreate } from "./../../../api/models/bus";

interface IBusCreateForm {
  username: string;
  name: string;
  description?: string;
  password: string;
  confirmPassword: string;
}

const BusCreate = () => {
  const [toasterVisible, setToasterVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const navigate = useNavigate();

  const createBusApi = useApi(busesApi.createBus);

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<IBusCreateForm>();

  const busRules = {
    username: {
      required: "Username is required",
    },
    name: { required: "Name is required" },
    description: {},
    password: {
      required: "Password is required",
      validate: {
        value: (password: any) =>
          password.length > 4 ||
          "The provided password must be at least 5 characters long",
      },
    },
    confirmPassword: {
      required: "Confirm Password is required",
      validate: {
        value: (confirmPassword: any) => {
          const password = getValues("password");

          if (confirmPassword.length > 4 && password === confirmPassword)
            return true;
          else {
            if (confirmPassword.length < 5)
              return "The provided password must be at least 5 characters long";
            if (password !== confirmPassword)
              return "The passwords do not match";
          }
        },
      },
    },
  };

  const onSubmit: SubmitHandler<IBusCreateForm> = async (
    data: IBusCreateForm
  ) => {
    setToasterVisible(false);
    const { confirmPassword, ...dataForApi } = data;

    const response = await createBusApi.request(dataForApi as IBusCreate);
    if (!response.ok) {
      setErrorMessage(response.data.errorMessage || response.data);
      setErrorModalVisible(true);
    } else {
      setToasterVisible(true);
      setTimeout(() => {
        navigate("/buses");
      }, 3000);
    }
  };

  return (
    <div className="w-full">
      <ModalLoader2 visible={createBusApi.loading} />
      <ModalError
        visible={errorModalVisible}
        errorMessage={errorMessage || ""}
        onClose={() => setErrorModalVisible(false)}
      />
      <ToasterSuccess
        message="Bus was created successfully."
        visible={toasterVisible}
        onClose={() => setToasterVisible(false)}
      />
      <section aria-label="bus details header">
        <PageHeader title="Create new bus" />
      </section>
      <section
        aria-label="bus details actions"
        className="flex flex-1 flex-row items-center h-16 border-y px-4 bg-red-50">
        <div className="absolute right-4">
          <Button label="Create" onClick={() => handleSubmit(onSubmit)()} />
        </div>
      </section>
      <section
        aria-label="bus details info"
        className="flex w-full px-8 justify-center">
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className="flex w-full flex-col justify-center items-center mt-6">
          <div>
            <div className="flex sm:flex-row flex-col sm:space-x-6">
              <div className="sm:w-1/2">
                <Controller
                  control={control}
                  name="username"
                  rules={busRules.username}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Field
                      type="text"
                      ref={ref}
                      placeholder="Bus username"
                      label="Username"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      isEditable={true}
                    />
                  )}
                />
                <Error
                  visible={Boolean(errors?.username)}
                  text={errors?.username?.message}
                />
              </div>
              <div className="sm:w-1/2">
                <Controller
                  control={control}
                  name="name"
                  rules={busRules.name}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Field
                      type="text"
                      ref={ref}
                      placeholder="Bus name"
                      label="Name"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      isEditable={true}
                    />
                  )}
                />
                <Error
                  visible={Boolean(errors?.name)}
                  text={errors?.name?.message}
                />
              </div>
            </div>
            <div className="mt-3">
              <Controller
                control={control}
                name="description"
                rules={busRules.description}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <TextArea
                    rows={5}
                    ref={ref}
                    placeholder="Bus description"
                    label="Description"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    isEditable={true}
                  />
                )}
              />
              <Error
                visible={Boolean(errors?.description)}
                text={errors?.description?.message}
              />
            </div>

            <div className="mt-3">
              <Controller
                control={control}
                name="password"
                rules={busRules.password}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <PasswordField
                    ref={ref}
                    placeholder="Bus password"
                    label="Password"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                  />
                )}
              />
              <Error
                visible={Boolean(errors?.password)}
                text={errors?.password?.message}
              />
            </div>
            <div className="mt-3">
              <Controller
                control={control}
                name="confirmPassword"
                rules={busRules.confirmPassword}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <PasswordField
                    ref={ref}
                    placeholder="Confirm bus password"
                    label="Confirm Password"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                  />
                )}
              />
              <Error
                visible={Boolean(errors?.confirmPassword)}
                text={errors?.confirmPassword?.message}
              />
            </div>
          </div>
        </form>
      </section>
      <section aria-label="bus details footer"></section>
    </div>
  );
};

export default BusCreate;
