import React from "react";

interface IProps {
  text?: string;
  visible?: boolean;
}

class Error extends React.PureComponent<IProps> {
  render() {
    return (
      <p
        className={`text-md text-primary h-2 text-xs ${
          this.props.visible ? "visible" : "invisible"
        }`}>
        {this.props.text}
      </p>
    );
  }
}

export default React.memo(Error);
