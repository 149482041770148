import React from "react";

interface IProps {
  label: string;
  ariaLabel?: string;
  onClick?: () => void;
}

class Button extends React.PureComponent<IProps> {
  render() {
    return (
      <button
        role="button"
        aria-label={this.props.ariaLabel}
        onClick={this.props.onClick}
        className="text-sm font-semibold leading-none text-white focus:outline-none bg-red-600 border rounded hover:bg-red-700 active:bg-red-800 p-4">
        {this.props.label}
      </button>
    );
  }
}

export default React.memo(Button);
