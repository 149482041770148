import React from "react";
import { useNavigate } from "react-router-dom";
//types
import { IPaginatorProps } from "../pagination";
import { IBus } from "../../../api/models/bus";
//components
import Paginator from "../pagination";

interface IProps {
  headers: string[];
  data?: IBus[];
  pagination: IPaginatorProps;
}

const BusListTemplate = (props: IProps) => {
  const navigate = useNavigate();

  function renderRow(row: any, i: number) {
    let cells: React.ReactNode[] = [];
    for (let j = 0; j < props.headers.length; j++)
      cells.push(
        <td
          className="pl-2 min-w-fit truncate border-l border-r"
          key={row._id + (j + 1).toString()}>
          {row[props.headers[j]]}
        </td>
      );
    return cells;
  }

  return props.data && props.data.length > 0 ? (
    <div className="flex ">
      <table className="w-full mt-8 border-collapse mx-8 text-left leading-8 table-fixed">
        <thead className="capitalize">
          <tr>
            {props.headers.map((x, i) => (
              <th className="min-w-fit pl-2  border-b" key={i.toString()}>
                {x.replace(/[A-Z]/g, " $&")}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.data.map((x, i) => (
            <tr
              key={x._id}
              className="border-b hover:bg-gray-200 cursor-pointer"
              onClick={() =>
                navigate("/buses/details", { state: { id: x._id } })
              }>
              {renderRow(x, i)}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td className="pt-8" colSpan={props.headers.length}>
              <Paginator
                currentPage={props.pagination.currentPage}
                pageSize={props.pagination.pageSize}
                totalItems={props.pagination.totalItems}
                onNavigate={(page) => props.pagination.onNavigate(page)}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  ) : null;
};

export default BusListTemplate;
