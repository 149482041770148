import React, { SVGProps } from "react";

class LogoShort extends React.PureComponent<SVGProps<SVGSVGElement>> {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="54"
        viewBox="0 0 44 54"
        {...this.props}>
        <g clipPath="url(#clip0_2215_204)">
          <path
            // fill="#E2231A"
            fill={this.props.fill || "#F9F9F9"}
            d="M18.166 7.279a7.29 7.29 0 01-1.203 3.998 7.113 7.113 0 01-3.184 2.645 6.98 6.98 0 01-4.093.403 7.052 7.052 0 01-3.624-1.975 7.243 7.243 0 01-1.936-3.688 7.317 7.317 0 01.405-4.16A7.181 7.181 0 017.14 1.27 7.006 7.006 0 0111.074.055a7.04 7.04 0 015.014 2.12 7.279 7.279 0 012.073 5.104M19.895 53.99C5.075 35.282-2.824 32.307.926 22.526c2.185-5.725 13.149-9.78 18.982-.522l-.013 31.986zM25.07 7.228a7.291 7.291 0 001.205 3.997 7.114 7.114 0 003.185 2.644 6.98 6.98 0 004.094.4 7.052 7.052 0 003.623-1.977 7.244 7.244 0 001.933-3.69 7.316 7.316 0 00-.407-4.16 7.18 7.18 0 00-2.61-3.23A7.005 7.005 0 0032.157 0a6.996 6.996 0 00-2.715.553 7.09 7.09 0 00-2.3 1.568 7.226 7.226 0 00-1.535 2.343 7.32 7.32 0 00-.536 2.762l-.001.002zM23.283 54C38.094 35.292 45.245 32.551 42.29 22.484c-1.684-5.744-13.187-9.743-19.008-.477V54z"></path>
        </g>
      </svg>
    );
  }
}

export default React.memo(LogoShort);
