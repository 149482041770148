import React from "react";

interface IProps {
  message: string;
  children: React.ReactNode;
}

class Tooltip extends React.PureComponent<IProps> {
  render() {
    return (
      <div className="relative flex flex-col items-center group">
        {this.props.children}
        <div className="absolute bottom-0 items-center hidden mb-6 group-hover:flex flex-col">
          <span className="relative z-10 p-2 bg-gray-600 shadow-lg rounded-md text-white text-xs leading-none capitalize whitespace-nowrap ">
            {this.props.message}
          </span>
          <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-600"></div>
        </div>
      </div>
    );
  }
}

export default React.memo(Tooltip);
