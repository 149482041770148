import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { BiEdit, BiTrash, BiLockAlt } from "react-icons/bi";

import PageHeader from "../../../components/layouts/header/page-header";
import Field from "../../../components/elements/forms/Field";
import TextArea from "../../../components/elements/forms/TextArea";
import Error from "../../../components/elements/forms/Error";
import Tooltip from "../../../components/elements/tooltip";
import ModalDialog from "../../../components/modals/modal-dialog";
import ModalInfo from "../../../components/modals/modal-info";
import ModalError from "../../../components/modals/modal-error";
import ToasterSuccess from "../../../components/elements/toasters/success";
import Button from "../../../components/elements/buttons/Button";
import ModalLoader2 from "./../../../components/modals/modal-loader-2/index";
import ModalBusChangePass from "../../../components/modals/buses/change-password";

import useApi from "../../../hooks/useApi";
import busesApi from "../../../api/main/buses";
import { IBus } from "../../../api/models/bus";
import PageFooter from "../../../components/layouts/footer/page-footer";

interface IState {
  id: string;
}

interface IBusFormData {
  username: string;
  name: string;
  description?: string;
}

const BusDetails = () => {
  const [data, setData] = useState<IBus>();
  const [editMode, setEditMode] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [deletionModalVisible, setDeletionModalVisible] = useState(false);
  const [toasterVisible, setToasterVisible] = useState(false);
  const [toasterMessage, setToasterMessage] = useState("");
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);

  const location = useLocation();
  const state = location.state as IState;
  const navigate = useNavigate();

  const getBusByIdApi = useApi(busesApi.getById);
  const updateBusApi = useApi(busesApi.updateBus);
  const deleteBusApi = useApi(busesApi.deleteBus);

  useEffect(() => {
    async function loadData() {
      const response = await getBusByIdApi.request(state.id);
      if (!response.ok) {
        setErrorMessage(response.data.errorMessage || response.data);
        setErrorModalVisible(true);
      } else {
        setValue("username", response.data.username);
        setValue("name", response.data.name);
        setValue("description", response.data.description);
        setData(response.data);
      }
    }
    loadData();
  }, []);

  useEffect(() => {
    if (editMode) return setIsUpdated(false);
    if (!editMode && !isUpdated) {
      if (data) {
        setValue("username", data.username);
        setValue("name", data.name);
        setValue("description", data.description);
      }
    }
  }, [editMode]);

  const handleDelete = async () => {
    const response = await deleteBusApi.request(state.id);
    if (!response.ok) {
      setErrorMessage(response.data.errorMessage || response.data);
      setErrorModalVisible(true);
    } else {
      setInfoModalVisible(true);
    }
  };

  const handleChangePass = () => {
    setEditMode(false);
    setChangePasswordVisible(true);
  };

  const handleChangePassSuccess = () => {
    setChangePasswordVisible(false);
    setToasterMessage("Bus password changed");
    setToasterVisible(true);
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<IBusFormData>();

  const busRules = {
    username: {
      required: "Username is required",
      // pattern: {
      //   value:
      //     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      //   message: "Invalid e-mail format",
      // },
      // validate: {
      //   value: (email: any) =>
      //     email.includes("@dlvs.com") ||
      //     "The provided e-mail must belong in the dlvs.com domain",
      // },
    },
    name: { required: "Name is required" },
    description: {},
  };

  const onSubmit: SubmitHandler<IBusFormData> = async (data: IBusFormData) => {
    const dataWithId: IBus = { _id: state.id, ...data };
    setToasterVisible(false);

    const response = await updateBusApi.request(dataWithId);
    if (!response.ok) {
      setErrorMessage(response.data.errorMessage || response.data);
      setErrorModalVisible(true);
    } else {
      setToasterVisible(true);
      setToasterMessage("Bus was updated successfully.");
      setEditMode(false);
      setData(response.data);
      setIsUpdated(true);
    }
  };

  return (
    <div className="w-full">
      <ModalDialog
        visible={deletionModalVisible}
        onClose={() => setDeletionModalVisible(false)}
        title="Delete"
        text={`Are you sure you want to delete ${data && data.name}?`}
        acceptButtonLabel="Delete"
        cancelButtonLabel="Cancel"
        handleAccept={handleDelete}
      />
      <ModalLoader2
        visible={
          getBusByIdApi.loading || updateBusApi.loading || deleteBusApi.loading
        }
      />
      <ModalError
        visible={errorModalVisible}
        errorMessage={errorMessage || ""}
        onClose={() => setErrorModalVisible(false)}
      />
      <ModalInfo
        visible={infoModalVisible}
        message="The bus was successfully deleted."
        onClose={() => navigate("/buses")}
      />
      <ToasterSuccess
        message={toasterMessage}
        visible={toasterVisible}
        onClose={() => setToasterVisible(false)}
      />
      <ModalBusChangePass
        id={state.id}
        visible={changePasswordVisible}
        onClose={() => setChangePasswordVisible(false)}
        onSuccess={handleChangePassSuccess}
      />
      <section aria-label="bus details header">
        <PageHeader title={(data && data.name) || ""} />
      </section>
      <section
        aria-label="bus details actions"
        className="flex flex-1 flex-row items-center h-16 border-y px-4 bg-red-50">
        <Tooltip message={`Edit Mode: ${editMode ? "ON" : "OFF"}`}>
          <BiEdit
            size="2rem"
            className={`p-1.5 mr-2 border rounded ${
              editMode ? "bg-gray-300" : "bg-gray-100"
            } transition duration-150 ease-in-out hover:bg-gray-200 active:shadow-none text-gray-600 shadow-[0px_0px_3px_0px_rgba(0,0,0,0.3)] cursor-pointer`}
            onClick={() => setEditMode(!editMode)}
          />
        </Tooltip>
        <Tooltip message="Change Password">
          <BiLockAlt
            size="2rem"
            className="p-1.5 mr-2 border rounded bg-gray-100 transition duration-150 ease-in-out hover:bg-gray-200 active:shadow-none text-gray-600 shadow-[0px_0px_3px_0px_rgba(0,0,0,0.3)] cursor-pointer"
            onClick={handleChangePass}
          />
        </Tooltip>
        <Tooltip message="Delete Bus">
          <BiTrash
            size="2rem"
            className="p-1.5 mr-2 border rounded bg-red-600 hover:bg-red-700 active:bg-red-800 active:shadow-none transition duration-150 ease-in-out text-white shadow-[0px_0px_3px_0px_rgba(0,0,0,0.3)] cursor-pointer"
            onClick={() => setDeletionModalVisible(true)}
          />
        </Tooltip>
        {editMode && (
          <div className="absolute right-4">
            <Button label="Update" onClick={() => handleSubmit(onSubmit)()} />
          </div>
        )}
      </section>
      <section
        aria-label="bus details info"
        className="flex w-full px-8 justify-center">
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className="flex w-full flex-col justify-center items-center mt-6">
          <div>
            <div className="flex sm:flex-row flex-col justify-around">
              <div className="sm:pr-8">
                <Controller
                  control={control}
                  name="username"
                  rules={busRules.username}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Field
                      type="text"
                      ref={ref}
                      placeholder="Bus username"
                      label="Username"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      isEditable={editMode}
                    />
                  )}
                />
                <Error
                  visible={Boolean(errors?.username)}
                  text={errors?.username?.message}
                />
              </div>
              <div className="mt-3 sm:mt-0">
                <Controller
                  control={control}
                  name="name"
                  rules={busRules.name}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Field
                      type="text"
                      ref={ref}
                      placeholder="Bus name"
                      label="Name"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      isEditable={editMode}
                    />
                  )}
                />
                <Error
                  visible={Boolean(errors?.name)}
                  text={errors?.name?.message}
                />
              </div>
            </div>
            <div className="mt-3">
              <Controller
                control={control}
                name="description"
                rules={busRules.description}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <TextArea
                    rows={5}
                    ref={ref}
                    placeholder="Bus description"
                    label="Description"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    isEditable={editMode}
                  />
                )}
              />
              <Error
                visible={Boolean(errors?.description)}
                text={errors?.description?.message}
              />
            </div>
          </div>
        </form>
      </section>
      <section
        aria-label="bus details footer"
        className="absolute bottom-1 right-4">
        <PageFooter />
      </section>
      {/* <h1>Bus with id: {state.id}</h1>
      {renderField({ label: "Username", value: "Bus 1" })} */}
    </div>
  );
};

export default BusDetails;
