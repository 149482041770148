import React from "react";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { IBusPassword } from "../../../api/models/bus";
import Error from "../../../components/elements/forms/Error";
import Button from "../../elements/buttons/Button";

import useApi from "../../../hooks/useApi";
import busesApi from "../../../api/main/buses";
import ModalError from "../modal-error";
import PasswordField from "../../elements/forms/PasswordField";
import ModalLoader2 from "./../modal-loader-2/index";

interface IProps {
  id: string;
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const ModalBusChangePass = ({ id, visible, onClose, onSuccess }: IProps) => {
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const cancelButtonRef = useRef(null);

  const changePasswordApi = useApi(busesApi.changePassword);

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<IBusPassword>();

  const busPasswordRules = {
    password: {
      required: "Password is required",
      validate: {
        value: (password: any) =>
          password.length > 4 ||
          "The provided password must be at least 5 characters long",
      },
    },
    confirmPassword: {
      required: "Confirm Password is required",
      validate: {
        value: (confirmPassword: any) => {
          const password = getValues("password");

          if (confirmPassword.length > 4 && password === confirmPassword)
            return true;
          else {
            if (confirmPassword.length < 5)
              return "The provided password must be at least 5 characters long";
            if (password !== confirmPassword)
              return "The passwords do not match";
          }
        },
      },
    },
  };

  const onSubmit: SubmitHandler<IBusPassword> = async (data: IBusPassword) => {
    data._id = id;
    const response = await changePasswordApi.request(data);
    if (!response.ok) {
      setErrorMessage(response.data.errorMessage || response.data);
      setErrorModalVisible(true);
    } else {
      setErrorModalVisible(false);
      onSuccess();
    }
  };

  return (
    <Transition.Root show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="flex flex-col justify-center items-center rounded-lg bg-white border shadow-[0px_0px_10px_0px_rgba(0,0,0,0.2)] text-left overflow-hidden transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                <ModalLoader2 visible={changePasswordApi.loading} />
                <ModalError
                  visible={errorModalVisible}
                  errorMessage={errorMessage}
                  onClose={() => setErrorModalVisible(false)}
                />
                <Dialog.Title
                  as="h3"
                  className="text-lg border-b w-full text-center bg-red-50 leading-6 font-medium text-gray-900 mb-6 py-3">
                  Change Bus Password
                </Dialog.Title>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                  className="w-full px-6">
                  <div className="w-full">
                    <div className="mt-0">
                      <Controller
                        control={control}
                        name="password"
                        rules={busPasswordRules.password}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <PasswordField
                            name={name}
                            ref={ref}
                            placeholder="New password"
                            label="Password"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                          />
                        )}
                      />
                      <Error
                        visible={Boolean(errors?.password)}
                        text={errors?.password?.message}
                      />
                    </div>
                    <div className="mt-4">
                      <Controller
                        control={control}
                        name="confirmPassword"
                        rules={busPasswordRules.confirmPassword}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <PasswordField
                            name={name}
                            ref={ref}
                            placeholder="Confirm new password"
                            label="Confirm Password"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                          />
                        )}
                      />
                      <Error
                        visible={Boolean(errors?.confirmPassword)}
                        text={errors?.confirmPassword?.message}
                      />
                    </div>
                  </div>
                  <div className="my-6 text-center">
                    <Button
                      label="Change Password"
                      aria-label="Bus Change Password"
                    />
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalBusChangePass;
