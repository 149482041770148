import React, { forwardRef, useState } from "react";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { Noop } from "react-hook-form";

interface IProps {
  placeholder?: string;
  label?: string;
  name: string;
  value?: string;
  onChange: (...events: any) => void;
  onBlur: Noop;
}

const PasswordField = forwardRef<HTMLInputElement, IProps>((props, ref) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <div>
      {props.label && (
        <label className="text-sm font-medium leading-none text-gray-800">
          {props.label || "Password"}
        </label>
      )}
      <div className="relative flex items-center justify-between border rounded">
        <input
          aria-label="enter password"
          role="input"
          name={props.name}
          placeholder={props.placeholder}
          ref={ref}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          type={passwordVisible ? "text" : "password"}
          className="bg-background w-full focus:outline-none text-xs font-medium leading-none text-gray-800 py-3 pl-3"
        />
        <div
          className="px-3 cursor-pointer bg-background flex flex-col justify-center h-10"
          role="button"
          aria-label="toggle password visibility"
          onClick={() => setPasswordVisible(!passwordVisible)}>
          {passwordVisible ? (
            <IoEyeOffOutline className="w-5 h-5 stroke-gray-500" />
          ) : (
            <IoEyeOutline className="w-5 h-5 stroke-gray-500" />
          )}
        </div>
      </div>
    </div>
  );
});

export default PasswordField;
