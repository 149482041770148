import React from "react";
import { Route, Routes } from "react-router-dom";

import Login from "../pages/auth/login";
import Register from "../pages/auth/register";

const AuthRouter = () => {
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path="/login" element={<Login />} />
      {/* <Route path="/register" element={<Register />} /> */}

      {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
      <Route path="*" element={<Login />} />
    </Routes>
  );
};

export default AuthRouter;
