import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { selectUserId } from "./features/authSlice";

import AuthRouter from "./routers/auth-router";
import MainRouter from "./routers/main-router";

function App() {
  const userId = useSelector(selectUserId);

  return (
    <BrowserRouter>
      {Boolean(userId) ? <MainRouter /> : <AuthRouter />}
    </BrowserRouter>
  );
}

export default App;
