import React from "react";
import { IconType } from "react-icons";

interface IProps {
  label: string;
  ariaLabel?: string;
  iconSide: "left" | "right";
  icon: IconType;
  onClick: () => void;
  props?: React.HTMLAttributes<HTMLButtonElement>;
}

class IconButton extends React.PureComponent<IProps> {
  render() {
    return (
      <button
        role="button"
        aria-label={this.props.ariaLabel || this.props.label}
        className=" max-w-fit p-1.5 sm:p-3 flex justify-center items-center text-white text-sm sm:text-md whitespace-nowrap font-semibold leading-none capitalize focus:outline-none bg-red-600 border rounded hover:bg-red-700 active:bg-red-800 transition-colors"
        // {...this.props.props}
        onClick={this.props.onClick}>
        {this.props.iconSide === "left" && (
          <this.props.icon className="pr-0.5 pt-0.5 text-xl sm:text-2xl" />
        )}
        <span className="px-2">{this.props.label}</span>
        {this.props.iconSide === "right" && (
          <this.props.icon className="pl-0.5 pt-0.5 text-xl sm:text-2xl" />
        )}
      </button>
    );
  }
}

export default React.memo(IconButton);
