import React from "react";

interface IProps {
  title: string;
}
class PageHeader extends React.PureComponent<IProps> {
  render() {
    return (
      <header className="pl-4 pt-1.5 sm:pt-2 text-left sm:text-4xl text-2xl leading-5xl font-semibold">
        {this.props.title}
      </header>
    );
  }
}

export default React.memo(PageHeader);
