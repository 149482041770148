import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { BiCalendar, BiSync, BiUserMinus } from "react-icons/bi";
import dayjs from "dayjs";

import ModalError from "../../../components/modals/modal-error";
import ModalLoader2 from "../../../components/modals/modal-loader-2";
import PageHeader from "../../../components/layouts/header/page-header";
import Field from "../../../components/elements/forms/Field";
import PageFooter from "../../../components/layouts/footer/page-footer";
import IconButton from "../../../components/elements/buttons/IconButton";
import Tooltip from "../../../components/elements/tooltip";
import ModalDialog from "../../../components/modals/modal-dialog";
import ToasterSuccess from "../../../components/elements/toasters/success";

import useApi from "../../../hooks/useApi";
import usersApi from "../../../api/main/users";
import { IUserDetails } from "../../../api/models/user";
import { selectUserRole } from "../../../features/authSlice";

interface IState {
  id: string;
}

const UserDetails = () => {
  const [data, setData] = useState<IUserDetails | undefined>(undefined);
  const [modalVisible, setModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterVisible, setToasterVisible] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();
  const state = location.state as IState;
  const role = useSelector(selectUserRole);

  const getUserApi = useApi(usersApi.getUserById);
  const syncUserApi = useApi(usersApi.syncUserWithOldBackend);
  const deleteUserApi = useApi(usersApi.deleteUserById);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm<IUserDetails>();

  const showDataInFields = (
    data: IUserDetails & { profile: { settings: { ddvConsent: { date: Date } } } }
  ) => {
    setValue("firstname", data.firstname);
    setValue("lastname", data.lastname);
    setValue("cpr", data.cpr);
    setValue("email", data.email);
    setValue("birthdate", data.birthdate);
    setValue("gender", data.gender);
    setValue("address", data.address);
    setValue("city", data.city);
    setValue("zip", data.zip);
    setValue("phone", data.phone);
    setValue("mobile", data.mobile);
    setValue("consentDate", data.consentDate);
    setValue("ddvConsentDate", data.profile?.settings?.ddvConsent?.date);
  };

  useEffect(() => {
    async function loadUser() {
      const response = await getUserApi.request(state.id);
      console.log(response.data);
      if (!response.ok) {
        setErrorMessage(response.data.errorMessage || response.data);
        setErrorModalVisible(true);
      } else {
        showDataInFields(response.data);
        setData(response.data);
      }
    }
    loadUser();
  }, []);

  const handleSyncUser = async () => {
    const response = await syncUserApi.request(state.id);
    if (!response.ok) {
      setErrorMessage(response.data.errorMessage || response.data);
      setErrorModalVisible(true);
      return;
    }
    showDataInFields(response.data);
    setData(response.data);
    setToasterMessage("User sync successful");
    setToasterVisible(true);
  };

  const handleDeleteUser = async () => {
    setModalVisible(false);
    const response = await deleteUserApi.request(state.id);
    if (!response.ok) {
      setErrorModalVisible(true);
      setErrorMessage(response.data.errorMEssage || response.data);
      return;
    }
    setToasterMessage("User deleted successfully");
    setToasterVisible(true);
    setTimeout(() => {
      navigate("/users");
    }, 3000);
  };

  return (
    <div className="w-full h-screen overflow-y-scroll">
      <ModalLoader2 visible={getUserApi.loading || syncUserApi.loading || deleteUserApi.loading} />
      <ModalError
        visible={errorModalVisible}
        errorMessage={errorMessage || ""}
        onClose={() => setErrorModalVisible(false)}
      />
      <ModalDialog
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        title="User Deletion"
        text={`Are you sure you want to delete the user and all user related data?
        This operation will delete data only from the Danske Lægers Vaccinations Service database.`}
        acceptButtonLabel="Delete"
        cancelButtonLabel="Cancel"
        handleAccept={handleDeleteUser}
      />
      <ToasterSuccess
        message={toasterMessage}
        visible={toasterVisible}
        onClose={() => setToasterVisible(false)}
      />
      <PageHeader title="User Details" />
      <section
        aria-label="user details actions"
        className="flex flex-1 flex-row items-center justify-between h-16 border-y px-4 bg-red-50">
        {/* <div>
          <IconButton
            iconSide="left"
            icon={BiCalendar}
            label="Appointments"
            onClick={() =>
              navigate("/users/appointments", { state: { id: state.id } })
            }
          />
        </div> */}
        {role === "admin" && (
          <div className="flex gap-8 mr-8">
            <Tooltip message="Sync with existing database">
              <IconButton
                iconSide="left"
                icon={BiSync}
                label="Sync User"
                onClick={handleSyncUser}
              />
            </Tooltip>
            <IconButton
              iconSide="left"
              icon={BiUserMinus}
              label="Delete User"
              onClick={() => setModalVisible(true)} //open modal , on accept delete user
            />
          </div>
        )}
      </section>
      <section className="flex justify-center py-12">
        <div className="grid grid-cols-1 lg:grid-cols-2 space-x-6 space-y-6 w-1/2">
          <div className="pl-6 pt-6">
            <Controller
              control={control}
              name="firstname"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Field
                  type="text"
                  ref={ref}
                  label="First Name"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  isEditable={false}
                />
              )}
            />
          </div>
          <div className="">
            <Controller
              control={control}
              name="lastname"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Field
                  type="text"
                  ref={ref}
                  label="Last Name"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  isEditable={false}
                />
              )}
            />
          </div>
          <div className="">
            <Controller
              control={control}
              name="cpr"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Field
                  type="text"
                  ref={ref}
                  label="CPR"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  isEditable={false}
                />
              )}
            />
          </div>
          <div className="">
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Field
                  type="text"
                  ref={ref}
                  label="e-mail"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  isEditable={false}
                />
              )}
            />
          </div>
          <div className="">
            <Controller
              control={control}
              name="birthdate"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Field
                  type="text"
                  ref={ref}
                  label="Birthdate"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={dayjs(value).format("DD/MM/YYYY")}
                  isEditable={false}
                />
              )}
            />
          </div>
          <div className="">
            <Controller
              control={control}
              name="gender"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Field
                  type="text"
                  ref={ref}
                  label="Gender"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value === "male" ? "Male" : "Female"}
                  isEditable={false}
                />
              )}
            />
          </div>
          <div className="">
            <Controller
              control={control}
              name="address"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Field
                  type="text"
                  ref={ref}
                  label="Address"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  isEditable={false}
                />
              )}
            />
          </div>
          <div className="">
            <Controller
              control={control}
              name="city"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Field
                  type="text"
                  ref={ref}
                  label="City"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  isEditable={false}
                />
              )}
            />
          </div>
          <div className="">
            <Controller
              control={control}
              name="zip"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Field
                  type="text"
                  ref={ref}
                  label="Zip Code"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  isEditable={false}
                />
              )}
            />
          </div>
          <div className="">
            <Controller
              control={control}
              name="phone"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Field
                  type="text"
                  ref={ref}
                  label="Phone Number"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value || "N/A"}
                  isEditable={false}
                />
              )}
            />
          </div>
          <div className="">
            <Controller
              control={control}
              name="mobile"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Field
                  type="text"
                  ref={ref}
                  label="Mobile Phone"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  isEditable={false}
                />
              )}
            />
          </div>
          <div className="">
            <Controller
              control={control}
              name="consentDate"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Field
                  type="text"
                  ref={ref}
                  label="Consent Date"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={dayjs(value).format("DD/MM/YYYY")}
                  isEditable={false}
                />
              )}
            />
          </div>
          <div className="">
            <Controller
              control={control}
              name="ddvConsentDate"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Field
                  type="text"
                  ref={ref}
                  label="DDV Consent Date"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value ? dayjs(value).format("DD/MM/YYYY") : "N/A"}
                  isEditable={false}
                />
              )}
            />
          </div>
        </div>
      </section>
      <div className="lg:absolute lg:bottom-0 right-0 lg:pr-4">
        <PageFooter />
      </div>
    </div>
  );
};

export default UserDetails;
