import client from "../client";
import { IPaginatedFilteredUsers, IUserFilters } from "./../models/user";

const endpoint = "/users";

const getAll = async (page: number, size: number) =>
  client.get(`${endpoint}?page=${page}&size=${size}`);
const getUserById = async (id: string) => client.get(`${endpoint}/${id}`);
const getUserCount = async () => client.get(`${endpoint}/total`);
const getFilteredUsers = async (data: IPaginatedFilteredUsers) =>
  client.post(`${endpoint}/get-filtered`, data);
const getAllFilteredUserIds = async (data: IUserFilters) =>
  client.post(`${endpoint}/get-all-filtered-ids`, data);
const getUserAppointments = (id: string) =>
  client.get(`${endpoint}/${id}/appointments`);
const syncUserWithOldBackend = (id: string) =>
  client.get(`${endpoint}/${id}/sync-with-old-backend`);
const deleteUserById = async (id: string) => client.delete(`${endpoint}/${id}`);

export default {
  getAll,
  getUserById,
  getUserCount,
  getFilteredUsers,
  getAllFilteredUserIds,
  getUserAppointments,
  syncUserWithOldBackend,
  deleteUserById,
};
