export default [
  {
    value: "questionaire.question3.answer1",
    label: "Jagt og fiskeri",
  },
  {
    value: "questionaire.question3.answer2",
    label: "Orienteringsløb/terrænløb/mountainbike",
  },
  {
    value: "questionaire.question3.answer3",
    label: "Vildmarks camping",
  },
  {
    value: "questionaire.question3.answer4",
    label: "Interaktion med dyr, fx fodring af aber",
  },
  {
    value: "questionaire.question3.answer5",
    label: "Kosmetiske operationer eller tandoperationer",
  },
  {
    value: "questionaire.question3.answer6",
    label: "Tatoveringer eller piercinger",
  },
  {
    value: "questionaire.question3.answer7",
    label: "Seksuel kontakt med lokalbefolkning",
  },
  {
    value: "questionaire.question3.answer8",
    label:
      "Arbejde med særlig risiko, fx med sanitære forhold, brønde, hospitalsarbejde el.l.",
  },
];
