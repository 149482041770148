import { IAdminChangePasswordForm } from "../../pages/main/admins/profile";
import client from "../client";
import { INotificationDto } from "./../models/notification";

const endpoint = "/admins";

interface IAdminCreateFormData {
  username: string;
}

const create = async (data: IAdminCreateFormData) =>
  client.post(`${endpoint}/`, data);
const changePassword = async (data: IAdminChangePasswordForm) =>
  client.post(`${endpoint}/me/change-password`, data);

const sendPushNotifications = async (data: INotificationDto) =>
  client.post(`${endpoint}/send-push-notifications`, data);

export default { create, changePassword, sendPushNotifications };
