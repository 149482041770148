import React from "react";

interface IProps {
  label: string;
  onChangeFrom: (value: string) => void;
  onChangeTo: (value: string) => void;
  valueFrom: string;
  valueTo: string;
}

function FromToField({
  label,
  onChangeFrom,
  onChangeTo,
  valueFrom,
  valueTo,
}: IProps) {
  return (
    <div className="flex flex-col w-40 text-sm font-normal leading-none text-gray-800">
      <p className="text-sm">{label}</p>
      <div className="flex space-x-3">
        <input
          type="text"
          placeholder="From"
          className="rounded-lg focus:outline-none w-16 pl-2 h-9 shadow-md"
          onChange={(e) => onChangeFrom(e.target.value)}
          value={valueFrom}
        />
        <span className="leading-10">-</span>
        <input
          type="text"
          placeholder="To"
          className="rounded-lg focus:outline-none w-16 pl-2 h-9 shadow-md"
          onChange={(e) => onChangeTo(e.target.value)}
          value={valueTo}
        />
      </div>
    </div>
  );
}

export default FromToField;
