import { create } from "apisauce";
import store from "../store/store";
import authApi from "./auth/auth";
import { userLoggedIn } from "../features/authSlice";

const apiClient = create({
  // baseURL: "https://dlvs.herokuapp.com/api", // Production
  // baseURL: "https://dlvs-test.herokuapp.com/api", // Staging
  // baseURL: "http://localhost:5001/api", // Local
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:5001/api",
});

apiClient.addAsyncRequestTransform(async (request) => {
  const authToken = store.getState().auth.accessToken;

  if (!authToken) return;
  request.headers["x-auth-token"] = authToken;
});

apiClient.addAsyncResponseTransform(async (response) => {
  if (response.status === 400 && response.data === "Token expired.") {
    const refreshToken: unknown = store.getState().auth.refreshToken;
    const refreshResponse = await authApi.refreshToken(refreshToken as string);

    if (!refreshResponse.ok) return;

    store.dispatch(
      userLoggedIn({
        accessToken: refreshResponse.data as string,
        refreshToken: refreshToken as string,
      })
    );
    //retry
    let retryResponse;
    if (response.config) {
      apiClient.setHeader("x-auth-token", refreshResponse.data as string);
      retryResponse = await apiClient.any(response.config);

      const newData: {} = retryResponse.data as {};
      response.ok = retryResponse.ok;
      response.status = retryResponse.status;
      response.data = newData;
      response.config = { ...retryResponse.config };
      response.duration = retryResponse.duration;
      response.headers = { ...retryResponse.headers };
      response.originalError = null;
      response.problem = retryResponse.problem;
    }
  }
});

export default apiClient;
