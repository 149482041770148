import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import jwtDecode from "jwt-decode";
import { RootState } from "../store/store";

interface IAuth {
  accessToken?: string | null;
  refreshToken?: string | null;
  userId: string | null;
  role: "admin" | "nurse" | null;
  isEmailValidated: boolean | null;
}
interface ILoginPayload {
  accessToken: string;
  refreshToken: string;
}

interface IUser {
  _id: string;
  role: "admin" | "nurse";
  isEmailValidated: boolean;
}
export const authSlice = createSlice({
  name: "auth",
  initialState: {
    accessToken: null,
    refreshToken: null,
    userId: null,
    role: null,
    isEmailValidated: null,
  },
  reducers: {
    userLoggedIn: (
      state: WritableDraft<IAuth>,
      action: PayloadAction<ILoginPayload>
    ) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;

      const user: IUser = jwtDecode(action.payload.accessToken);
      state.userId = user._id;
      state.role = user.role;
      state.isEmailValidated = user.isEmailValidated;
    },
    userLoggedOut: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.userId = null;
      state.role = null;
      state.isEmailValidated = null;
    },
  },
});

export const selectUserId = (state: RootState) => state.auth.userId;
export const selectUserRole = (state: RootState) => state.auth.role;
export const selectUserIsEmailValidated = (state: RootState) =>
  state.auth.isEmailValidated;

export const { userLoggedIn, userLoggedOut } = authSlice.actions;

export default authSlice.reducer;
