import React, { useEffect, useState } from "react";
import { BiCheckCircle, BiX } from "react-icons/bi";

interface IProps {
  message: string;
  visible: boolean;
  onClose: () => void;
}

const ToasterSuccess = (props: IProps) => {
  const [visible, setVisible] = useState(props.visible);

  useEffect(() => {
    if (props.visible) {
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        props.onClose();
      }, 3000);
    }
  }, [props.visible]);

  return (
    <article
      className={`fixed rounded-lg z-10 top-4 right-6 transition-all duration-500 shadow-[2px_2px_4px_1px_rgba(0,0,0,0.2)] ${
        visible ? "opacity-100" : "opacity-0"
      }`}>
      <BiX
        size="1.25rem"
        className="absolute top-1 right-1 text-gray-500 cursor-pointer"
        onClick={() => setVisible(false)}
      />
      <div className="flex w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg">
        <div className="flex flex-1 items-center justify-center bg-emerald-500 px-4">
          <BiCheckCircle size="1.75rem" className="text-white" />
        </div>
        <div className="pl-4 pr-8 py-2 flex flex-2 flex-col">
          <span className="font-semibold text-emerald-500">Success</span>
          <p className="text-sm text-gray-600">{props.message}</p>
        </div>
      </div>
    </article>
  );
};

export default ToasterSuccess;
