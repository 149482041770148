import React, { SVGProps } from "react";

class LogoThor extends React.PureComponent<SVGProps<SVGSVGElement>> {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={120}
        height={120}
        viewBox={"0 0 120 120"}
        fill="none"
        {...this.props}>
        <path
          d="m47.649 29.961-8.234 8.309L0 18.252h43.532c0 3.839.412 7.129 4.117 11.709zM58.353 101.748 41.61 40.874l8.509-8.638c2.333 1.645 4.941 2.879 8.234 3.231v66.28zM72.351 29.961l8.234 8.309L120 18.252H76.468c0 3.839-.412 7.129-4.117 11.709zM61.647 101.748 78.39 40.874l-8.509-8.638c-2.333 1.645-4.94 2.879-8.234 3.231v66.28zM58.628 21.542c-4.007.373-4.071 1.06-4.117 1.782l-.218 1.322c-.023.163-.046.303-.046.466l-.046.466-.046.489-.046.489-.147 4.638c2.055.743 3.789 1.04 5.723 1.04s4.146-.317 5.942-1.04c-.092-.489-.442-1.119-.557-1.655l-.23-1.095-.115-.559-.253-1.189.645-.373c.023 0 .023-.023.046-.023.069-.047.138-.07.207-.116 1.405-.793 1.796-1.002 3.316 0 .806-4.428-4.214-4.78-10.057-4.643z"
          fill="url(#a)"
        />
        <defs>
          <linearGradient
            id="a"
            x1={-0.11}
            y1={18.252}
            x2={45.586}
            y2={102.037}
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#0f0f0f" />
            <stop offset={0.091} stopColor="#3a3a3a" />
            <stop offset={0.139} stopColor="#575757" />
            <stop offset={0.188} stopColor="#3a3a3a" />
            <stop offset={0.255} stopColor="#3a3a3a" />
            <stop offset={0.327} stopColor="#575757" />
            <stop offset={0.418} stopColor="#575757" />
            <stop offset={0.55} stopColor="#3a3a3a" />
            <stop offset={0.683} stopColor="#575757" />
            <stop offset={0.837} stopColor="#3a3a3a" />
            <stop offset={1} stopColor="#0f0f0f" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
}

export default React.memo(LogoThor);
