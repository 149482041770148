import { isEditable } from "@testing-library/user-event/dist/utils";
import React, { forwardRef, HTMLInputTypeAttribute } from "react";
import { Noop } from "react-hook-form";

interface IProps {
  placeholder?: string;
  type: HTMLInputTypeAttribute;
  label?: string;
  value?: string;
  isEditable?: boolean;
  onChange: (...events: any) => void;
  onBlur: (...events: any) => void;
}

const Field = forwardRef<HTMLInputElement, IProps>((props, ref) => {
  return (
    <>
      {props.label && (
        <label className="text-sm font-medium leading-none text-gray-800">
          {props.label}
        </label>
      )}
      <input
        aria-label="enter email adress"
        role="input"
        placeholder={props.placeholder}
        type={props.type}
        value={props.value}
        disabled={!props.isEditable || false}
        onBlur={props.onBlur}
        onChange={props.onChange}
        ref={ref}
        className="bg-background w-full focus:outline-none border text-xs font-medium leading-none text-gray-800 p-3"
      />
    </>
  );
});

export default Field;
