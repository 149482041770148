import React from "react";
//icons
import {
  CgPushChevronLeft,
  CgChevronLeft,
  CgChevronRight,
  CgPushChevronRight,
} from "react-icons/cg";

export interface IPaginatorProps {
  pageSize: number;
  totalItems: number;
  currentPage: number;
  maxPagesShown?: number;
  onNavigate: (page: number) => void;
}

class Paginator extends React.PureComponent<IPaginatorProps> {
  render() {
    const { pageSize, currentPage, totalItems, onNavigate } = this.props;
    let { maxPagesShown = 7 } = this.props;

    maxPagesShown = maxPagesShown < 3 ? 3 : maxPagesShown; // At least 3 pages
    let totalPages = Math.ceil(totalItems / pageSize);
    totalPages = totalPages < 1 ? 1 : totalPages;
    //input validation
    if (currentPage > totalPages)
      throw new Error("current page cannot exceed the total page number.");
    if (currentPage < 1 || pageSize < 1)
      throw new Error(
        "currentPage, pageSize and totalItems cannot be less than 1"
      );
    const from = totalItems === 0 ? 0 : (currentPage - 1) * pageSize + 1;
    const to = from + pageSize - 1;

    const pagesShown = () => {
      // can fit all
      if (totalPages <= maxPagesShown)
        return new Array(totalPages).fill(0).map((x, i) => i + 1);
      // can fit both page 1 and current page (with current page in middle)
      if (currentPage - Math.ceil(maxPagesShown / 2) <= 0)
        return new Array(maxPagesShown).fill(0).map((x, i) => i + 1);
      // can fit current page and last page
      if (currentPage + Math.floor(maxPagesShown / 2) >= totalPages)
        return new Array(maxPagesShown)
          .fill(0)
          .map((x, i) => totalPages - maxPagesShown + i + 1);
      // has previous and next values outside the maxPagesShown range
      return new Array(maxPagesShown)
        .fill(0)
        .map((x, i) => currentPage - Math.ceil(maxPagesShown / 2) + i + 1);
    };

    const handlePageClick = (pageNumber: number) => {
      if (pageNumber === currentPage) return;
      onNavigate(pageNumber);
    };

    //styles
    const inactiveButtonStyle =
      "w-8 h-8 flex justify-center items-center bg-gray-100 transition duration-150 ease-in-out hover:bg-gray-200 rounded-full text-gray-600 text-sm cursor-pointer shadow-[0px_0px_10px_0px_rgba(0,0,0,0.3)]";
    const activeButtonStyle =
      "w-8 h-8 flex justify-center items-center bg-gray-200 transition duration-150 ease-in-out rounded-full text-gray-600 text-sm shadow-[0px_0px_10px_0px_rgba(0,0,0,0.3)] font-bold";
    const renderPages = () =>
      pagesShown().map((x) => (
        <div
          key={x.toString()}
          className={
            x === currentPage ? activeButtonStyle : inactiveButtonStyle
          }
          onClick={() => handlePageClick(x)}>
          {x}
        </div>
      ));

    return (
      <div className="w-full flex flex-col items-center text-base">
        <div className="flex justify-around items-center text-center gap-2">
          <CgPushChevronLeft
            onClick={() => onNavigate(1)}
            className={`cursor-pointer ${
              currentPage === 1 ? "text-gray-300 cursor-default" : ""
            }`}
          />
          <CgChevronLeft
            onClick={() =>
              onNavigate(currentPage - 1 < 1 ? 1 : currentPage - 1)
            }
            className={`cursor-pointer ${
              currentPage === 1 ? "text-gray-300 cursor-default" : ""
            }`}
          />
          {renderPages()}
          <CgChevronRight
            onClick={() =>
              onNavigate(
                currentPage + 1 > totalPages ? totalPages : currentPage + 1
              )
            }
            className={`cursor-pointer ${
              currentPage === totalPages ? "text-gray-300 cursor-default" : ""
            }`}
          />
          <CgPushChevronRight
            onClick={() => onNavigate(totalPages)}
            className={`cursor-pointer ${
              currentPage === totalPages ? "text-gray-300 cursor-default" : ""
            }`}
          />
        </div>
        <div className="block mt-2 text-sm">{`Viewing page ${currentPage} of ${totalPages}`}</div>
        <div className="block text-xs">{`(${from} - ${
          to > totalItems ? totalItems : to
        } of ${totalItems} total items)`}</div>
      </div>
    );
  }
}

export default React.memo(Paginator);
