import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Field from "../../../components/elements/forms/Field";
import PasswordField from "../../../components/elements/forms/PasswordField";
import Error from "../../../components/elements/forms/Error";
import Button from "../../../components/elements/buttons/Button";

import authApi from "../../../api/auth/auth";
import useApi from "./../../../hooks/useApi";
import sanitizeHtml from "../../../utils/helpers/sanitizeHtml";
import { userLoggedIn } from "../../../features/authSlice";
import ModalError from "../../../components/modals/modal-error";
import ModalLoader2 from "../../../components/modals/modal-loader-2";
import Logo from "../../../assets/Logo";

export interface ILoginFormData {
  username: string;
  password: string;
}

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorModalVisible, setErrorModalVisible] = useState(false);

  const loginApi = useApi(authApi.login);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ILoginFormData>();

  const loginRules = {
    username: {
      required: "Username is required",
      // pattern: {
      //   value:
      //     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      //   message: "Invalid e-mail format",
      // },
      // validate: {
      //   value: (email: any) =>
      //     email.includes("@dlvs.com") ||
      //     "The provided e-mail must belong in the dlvs.com domain",
      // },
    },
    password: { required: "Password is required" },
  };

  const onSubmit: SubmitHandler<ILoginFormData> = async (
    data: ILoginFormData
  ) => {
    data.username = sanitizeHtml(data.username);
    const response = await loginApi.request(data);
    if (!response.ok) {
      setErrorModalVisible(true);
    } else {
      const authData = {
        accessToken: response.headers!["x-auth-token"],
        refreshToken: response.headers!["x-refresh-token"],
      };
      navigate("/", { replace: true });
      dispatch(userLoggedIn(authData));
    }
  };

  return (
    <div className="w-full h-screen bg-gradient-to-bl from-primary to-secondary py-16 px-4">
      <ModalError
        visible={errorModalVisible}
        errorMessage={loginApi.data && loginApi.data?.errorMessage}
        onClose={() => setErrorModalVisible(false)}
      />
      <ModalLoader2 visible={loginApi.loading} />
      <section className="flex flex-row items-center justify-center mt">
        <img
          src={require("../../../assets/img/DLVS-admin-panel-logo-2.png")}
          className="w-12 h-12"
        />
        <h1 className="text-white text-3xl font-black ml-5">
          Danske Lægers Vaccinations Service
        </h1>
      </section>
      <section className="flex flex-col items-center justify-center">
        <div className="bg-white shadow rounded xl:w-1/3 lg:w-1/2 md:w-2/3 w-full p-10 mt-16">
          <p
            tabIndex={0}
            role="heading"
            aria-level={2}
            aria-label="Login to your account"
            className="text-2xl font-extrabold leading-6 text-gray-800">
            Login to your account
          </p>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="mt-6">
              <Controller
                control={control}
                name="username"
                rules={loginRules.username}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Field
                    type="text"
                    ref={ref}
                    placeholder="Enter your username"
                    label="Username"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value || ""}
                    isEditable={true}
                  />
                )}
              />
              <Error
                visible={Boolean(errors?.username)}
                text={errors?.username?.message}
              />
            </div>
            <div className="mt-6 ">
              <Controller
                control={control}
                name="password"
                rules={loginRules.password}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <PasswordField
                    name={name}
                    ref={ref}
                    placeholder="Enter your password"
                    label="Password"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value || ""}
                  />
                )}
              />
              <Error
                visible={Boolean(errors?.password)}
                text={errors?.password?.message}
              />
            </div>
            <div className="mt-8 text-center">
              <Button
                label="Login to my account"
                ariaLabel="Login to my account"
              />
            </div>
          </form>
        </div>
      </section>
      <div className="absolute bottom-2 right-8 flex h-auto justify-end w-full">
        <a
          target="_blank"
          href="https://www.thor-solutions.com"
          className="flex items-center opacity-30 hover:opacity-80 transition-all duration-1000 hover:scale-110">
          {/* <LogoFullWhite style={{ width: "auto", height: 60 }} /> */}
          <Logo style={{ height: 36 }} />
          <p className="uppercase text-white -ml-10 text-lg tracking-wide">
            Thor Solutions
          </p>
        </a>
      </div>
    </div>
  );
};

export default Login;
