export default [
  {
    value: "1",
    label: "Hovedstaden",
  },
  {
    value: "2",
    label: "Sjælland",
  },
  {
    value: "3",
    label: "Syddanmark",
  },
  {
    value: "4",
    label: "Midtjylland",
  },
  {
    value: "5",
    label: "Nordjylland",
  },
];
