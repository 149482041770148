import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import Sidebar from "../components/layouts/sidebar/Sidebar";
import NotFound from "../pages/common/not-found";
import BusesList from "../pages/main/buses/list";
import BusDetails from "../pages/main/buses/details";
import BusCreate from "./../pages/main/buses/create";
import {
  selectUserIsEmailValidated,
  selectUserRole,
} from "./../features/authSlice";
import UserDetails from "../pages/main/users/details";
import UserAppointments from "./../pages/main/users/appointments";
import AppointmentQuestionaire from "../pages/main/users/questionnaire";
import UsersList2 from "../pages/main/users/list2";
import Notifications from "../pages/main/users/notifications";
import NursesList from "./../pages/main/nurses/list";
import NurseCreate from "./../pages/main/nurses/create";
import NurseDetails from "./../pages/main/nurses/details";
import NursesVerifyEmail from "./../pages/main/nurses/verify-email";
import AdminProfile from "./../pages/main/admins/profile";
import NurseProfile from "../pages/main/nurses/profile";

const MainRouter = () => {
  const role = useSelector(selectUserRole);
  const isNurseEmailValidated = useSelector(selectUserIsEmailValidated);

  const conditionalRoutes = () => {
    if (role === "admin")
      return (
        <>
          <Sidebar />
          <Routes>
            <Route index element={<Navigate to="/users" />} />
            <Route path="buses/details" element={<BusDetails />} />
            <Route path="buses/create" element={<BusCreate />} />
            {/* <Route path="nurses/details" element={<NurseDetails />} /> */}
            {/* <Route path="nurses/create" element={<NurseCreate />} /> */}
            <Route path="users/details" element={<UserDetails />} />
            <Route path="users/notifications" element={<Notifications />} />
            {/* <Route path="users/appointments/questionnaire" element={<AppointmentQuestionaire />} /> */}
            {/* <Route path="users/appointments" element={<UserAppointments />} /> */}
            <Route path="buses" element={<BusesList />} />
            {/* <Route path="nurses" element={<NursesList />} /> */}
            <Route path="users" element={<UsersList2 />} />
            <Route path="profile" element={<AdminProfile />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </>
      );

    // if (role === "nurse")
    //   if (!isNurseEmailValidated)
    //     return (
    //       <Routes>
    //         <Route index element={<Navigate to="/verify-email" />} />
    //         <Route path="/verify-email" element={<NursesVerifyEmail />} />
    //         <Route path="*" element={<NotFound />} />
    //       </Routes>
    //     );
    //   else
    //     return (
    //       <>
    //         <Sidebar />
    //         <Routes>
    //           <Route index element={<Navigate to="/users" />} />
    //           <Route path="users/details" element={<UserDetails />} />
    //           <Route path="users/notifications" element={<Notifications />} />
    //           <Route
    //             path="users/appointments/questionnaire"
    //             element={<AppointmentQuestionaire />}
    //           />
    //           <Route path="users/appointments" element={<UserAppointments />} />
    //           <Route path="users" element={<UsersList2 />} />
    //           <Route path="profile" element={<NurseProfile />} />
    //           <Route path="*" element={<NotFound />} />
    //         </Routes>
    //       </>
    //     );
  };

  return (
    // <BrowserRouter>
    <div className="flex flex-row">{conditionalRoutes()}</div>
    // </BrowserRouter>
  );
};

export default MainRouter;
