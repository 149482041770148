import { ApiResponse } from "apisauce";
import { useState } from "react";

const useApi = (apiFunc: (...args: any) => Promise<ApiResponse<any, any>>) => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const request = async (...args: any) => {
    setLoading(true);
    // get new access token from refresh
    const response = await apiFunc(...args);
    setLoading(false);

    setError(!response.ok);
    setData(response.data);
    return response;
  };

  return { data, error, loading, request };
};

export default useApi;
