import React from "react";
import { GiScaleMail, GiThorHammer } from "react-icons/gi";
import LogoThor from "../../../assets/LogoThor";

interface IProps {
  title?: string;
}
class PageFooter extends React.PureComponent<IProps> {
  render() {
    return (
      <footer className="flex flex-1 items-end justify-end pl-4 pt-4 text-left sm:text-4xl text-2xl leading-5xl sm:leading-5xl font-semibold ">
        <a
          target="_blank"
          href="https://www.thor-solutions.com"
          className="flex flex-row justify-end items-center">
          <LogoThor width={30} height={30} />
          {/* <GiThorHammer className="text-base mt-0.5" /> */}
          <p className="text-sm mx-2 font-extralight font-mono">
            Powered by Thor Solutions
          </p>
        </a>
      </footer>
    );
  }
}

export default React.memo(PageFooter);
